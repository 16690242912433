<template>
  <div>
    <Datatable ref="datatable" route="/client/liste?fake_dist=true" :fields="fields" :data_filters="filters" titre="Liste des prospects" :sort="sort">      
      <template #action>                  
      </template>              
      <template #cell(telephone_1)="data">
        <Telephone v-model="data.item.telephone_1" :edit="false" />
      </template>
      <template #cell(email)="data">
        <a :href="`mailto:${data.value}`">{{ data.value }}</a>
      </template>      
      <template #cell(regime_id)="data">
        <span>{{ $api.getParam('Regime', data.value).titre }}</span>
      </template>
      <template #cell(fake_dist)="data">
        <span>{{ $api.getParam('User', data.value).titre }}</span>
      </template>
      <template #cell(source_id)="data">
        <span>{{ $api.getParam('Source', data.value).titre }}</span>
      </template>      
      <template #cell(date_effet)="data">
        {{ $api.moment(data.value).format("DD/MM/YYYY") }}
      </template>      
      <template #cell(date_create)="data">
        {{$api.timestampToDate(data.value)}}
      </template>      
      <template #cell(date_update)="data">
        {{$api.timestampToDate(data.value)}}
      </template>      
      <template #cell(status_id)="data">
        <b-dropdown v-b-tooltip.hover title="Changer le statut du prospect" no-caret  :variant="$api.p('ClientStatus', data.value).color" size="md">
          <template #button-content>
            {{ $api.p('ClientStatus', data.value).titre }} <i class="ml-2 fas fa-chevron-down"></i>
          </template>
          <b-dropdown-item v-for="(s,i) in $api.params.ClientStatus" :key="i" :disabled="$api.params.StatusLock.prospect_lock.includes(data.value)" :active="data.value === s.id">{{s.titre}}</b-dropdown-item>
        </b-dropdown>        
      </template>
    </Datatable>        
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
import Telephone from '@/components/Telephone';
export default {
  name: "ClientListe",
  components: {
    Datatable,Telephone
  },
  watch:{    
  },
  data(){ 
    var fields = [{key: 'id', label: 'Ref', sortable:true,}]
    if(this.$api.acl(19)){
      fields.push({ key: 'action', label: 'Action', sortable:false, });
    }
    fields = fields.concat([ 
      { key: 'nom', label: 'Nom', sortable:true, }, 
      { key: 'telephone_1', label: 'Telephone 1', sortable:false, }, 
      { key: 'email', label: 'Email', sortable:false, }, 
      { key: 'date_effet', label: 'Date effet', sortable:false, }, 
      { key: 'fake_dist', label: 'Commercial', sortable:true, }, 
      { key: 'source_id', label: 'Source', sortable:true, }, 
      { key: 'date_create', label: 'Creation', sortable:true, }, 
      { key: 'date_update', label: 'Modification', sortable:false, }, 
      { key: 'status_id', label: 'Statut', sortable:true, }, 
    ]);
    return{
      filters:{        
      },
      fields: fields,      
      sort:{
        key: 'date_update',
        value: 'DESC'
      },
      loadMail:false,
      tplMail: null,
      selected:[],
      loadDistribution: false,
      user_id: null,      
    }
  },
  computed:{
    mails(){
      var type = "ClientMail";
      return this.$api.params.Template.filter(t => t.type === type);
    }    
  },
  methods: {       
    print(){
      window.print();
    },
    toCSV(){
      var rows = [];
      var title = ['ID','Nom', 'Telephone 1', 'Email', 'Date effet', 'Commercial', 'Source', 'Dae creation', 'Statut'];
      rows.push(title.join(';'));
      this.$refs.datatable.datatable.data.forEach((r) => {
        var tmp = [];
        tmp.push(r.id);
        tmp.push(r.nom);
        tmp.push(r.telephone_1);
        tmp.push(r.email);
        tmp.push(r.date_effet);
        tmp.push(this.$api.p('User', r.fake_dist).titre);          
        tmp.push(this.$api.p('Source', r.source_id).titre);          
        tmp.push(this.$api.moment(r.date_create * 1000).format("DD/MM/YYYY HH:mm:ss"));
        tmp.push(this.$api.p('ClientStatus', r.status_id).titre);          
        rows.push(tmp.join(';'));        
      });
      rows = rows.join("\n");
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(rows);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Export.csv';
      hiddenElement.click();
    },
    checkSelect(lead_id){
      return this.selected.filter(s => s === lead_id).length === 1;
    },    
  },
  beforeMount() {
  },
  mounted() {        
  }
}
</script>
<style>
@media print {
  .menu-desktop, #menu-mobile, #header{
    display: none !important;
  }            
}
</style>
